// filename -App.js
//<Route
//path="/queuehelper"
//element={<QueueHelper />}
///>

import { useState } from 'react';
import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import {
    HashRouter,
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import About from "./about";
import QueueHelper from "./components/pages/queuehelper";
import Homepage from "./components/pages/homepage";
import CheckpointList from "./components/pages/checkpointlist";

function App() {
    const [reloadKey, setReloadKey] = useState(0);
      
    return (
        <HashRouter>
            
                <Navbar />
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/about" element={<About />} />
                    <Route
                        path="/queuehelper"
                        element={<QueueHelper />}
                    />
                    <Route
                        path="/checkpointlist"
                        key ={reloadKey}
                        element={<CheckpointList />}
                    />
                </Routes>
            
        </HashRouter>
    );
}

export default App;